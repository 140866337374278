<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="渠道可售库存分配设置"
    :visible.sync="visible"
    center
    inline
    top="5vh"
    width="800px"
  >
    <el-form label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="仓库" prop="warehouseName">
            <el-input v-model="form.warehouseName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备货渠道" prop="warehouseName">
            <el-input v-model="form.warehouseName" disabled />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item>
            <vxe-toolbar>
              <template #buttons>
                <div style="display: flex; align-items: center">
                  <el-button type="primary" @click="handleAddStore">
                    添加店铺
                  </el-button>
                  <div
                    class="ml-2 pl-1 pr-1"
                    style="background: #fbe7ba; border-radius: 4px;display:flex;align-items:center;`"
                  >
                    <span
                      class="el-icon-info mr-2"
                      style="color: blue; font-size: 20px"
                    />仅以下配置店铺可使用该渠道库存售卖
                  </div>
                </div>
              </template>
            </vxe-toolbar>
            <vxe-table
              class="mt-3"
              :data="shopList"
              :show-overflow="false"
              align="center"
              resizable
              :scroll-x="{ enabled: false }"
              :scroll-y="{ enabled: false }"
              height="400px"
              border
            >
              <vxe-column>
                <template #header>
                  <span class="text-red">*</span>
                  <span class="ml-2">平台 / 站点 / 店铺</span>
                </template>
                <template #default="{ row }">
                  <el-cascader
                    v-model="row.platformSiteShop"
                    style="width: 100%"
                    :options="platformSiteShopOptions"
                    placeholder="平台/站点/店铺"
                    filterable
                  />
                </template>
              </vxe-column>
              <vxe-column width="300px">
                <template #header>
                  <span class="text-red">*</span>
                  <span class="ml-2">占比（%）</span>
                </template>
                <template #default="{ row }">
                  <el-input-number
                    v-model="row.percentage"
                    placeholder="请输入"
                    type="number"
                    :controls="false"
                    :min="1"
                    :max="100"
                  />
                  <el-button type="text" @click="handleDeleteChannel(i)">
                    <span class="el-icon-delete ml-3" />
                  </el-button>
                </template>
              </vxe-column>
            </vxe-table>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div style="text-align: right">
        <el-button @click="handleClose">取消</el-button>
        <el-button
          type="primary"
          :loading="submitLoading"
          @click="handleConfirm"
        >确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getPlatformSiteShopByUserId } from '@/views/inventory-visualization/sellable-inventory/apis/list'
import { setShareInventoryAssign } from '@/api/inventory-management'
// import _ from 'lodash'
export default {
  name: 'DisbributeDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentRow: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        channelId: '',
        warehouseName: '',
        channelName: ''
      },
      shopList: [],
      submitLoading: false,
      platformSiteShopOptions: []
    }
  },
  computed: {
    submitParams() {
      const { warehouseCode } = this.currentRow
      const details = this.shopList.map((item) => {
        const [plat, site, shop] = item.platformSiteShop
        return {
          plat,
          site,
          shop,
          percentage: item.percentage
        }
      })

      return {
        channelId: this.form.channelId,
        details,
        wareCode: warehouseCode
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        const { warehouseName, channelName, channelId, platDetails } =
          this.currentRow

        Object.assign(this.form, {
          warehouseName,
          channelName,
          channelId
        })

        this.shopList = (platDetails || []).map((item) => {
          const { plat, site, shop, percentage } = item
          return {
            platformSiteShop: [plat, site, shop],
            percentage
          }
        })
      }
    }
  },
  mounted() {
    this.getPlatformSiteShopOptions()
  },
  methods: {
    handleAddStore() {
      this.shopList.push({
        platformSiteShop: [],
        percentage: 100
      })
    },
    handleDeleteChannel(i) {
      this.shopList.splice(i, 1)
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('update:currentRow', null)
      this.shopList = []
    },
    async handleConfirm() {
      try {
        const shopList = this.shopList
        if (!shopList.length) {
          this.$message.warning('请添加店铺')
          return
        }

        const isIncompleteInfo = shopList.every((item) => {
          if (!item.platformSiteShop.length) {
            this.$message.warning('平台/站点/店铺，不能为空')
            return false
          }

          if (!item.percentage) {
            this.$message.warning('占比，不能为空')
            return false
          }
          return true
        })

        // const percentageTotal = shopList.reduce((acc, item) => {
        //   acc += _.isNumber(item.percentage) ? item.percentage : 0
        //   return acc
        // }, 0)

        if (!isIncompleteInfo) {
          return
        }

        // if (percentageTotal !== 100) {
        //   this.$message({
        //     type: 'warning',
        //     message: '各店铺占比之和不等于100%，请将渠道可售库存全部分配到店铺'
        //   })
        //   return
        // }
        this.submitLoading = true
        const { code, msg } = await setShareInventoryAssign({
          ...this.submitParams
        }).finally(() => {
          this.submitLoading = false
        })

        if (code === 0) {
          this.$message.success(msg)
          this.handleClose()
          this.$emit('refresh')
        } else {
          this.$message.error(msg || '操作失败')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getPlatformSiteShopOptions() {
      const { datas } = await getPlatformSiteShopByUserId()
      this.platformSiteShopOptions = datas || []
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog--center {
  text-align: left;
}
.text-red {
  color: red;
}
</style>
