export const BatchImportInventoryDistributeOptions = {
  gridOptions: {
    columns: [
      { field: 'warehouseCode', title: '履约仓库' },
      { field: 'channelId', title: '备货渠道' },
      { field: 'plat', title: '平台' },
      { field: 'site', title: '站点' },
      { field: 'shop', title: '店铺' },
      { field: 'percentage', title: '占比（%)' },
      { field: 'errorMsg', title: '错误信息' }
    ]
  }
}

export const templateDownloadUrl = 'https://aliyun-oa-query-results-1509030252432744-oss-cn-hongkong.oss-cn-hongkong.aliyuncs.com/template/%E5%AF%BC%E5%85%A5%E6%B8%A0%E9%81%93%E5%8F%AF%E5%94%AE%E5%BA%93%E5%AD%98%E5%88%86%E9%85%8D%E4%BF%A1%E6%81%AF.xlsx'
