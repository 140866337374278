<template>
  <div>
    <el-form
      ref="formRef"
      :model="form"
      :rules="rules"
      inline
      label-width="150"
      size="small"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="履约仓库" prop="wareCode">
            <el-select v-model="form.wareCode" clearable filterable>
              <el-option
                v-for="(item, index) in warehouseOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备货渠道" prop="chanelId">
            <el-select
              v-model="form.chanelId"
              multiple
              clearable
              collapse-tags
              filterable
              :disabled="!form.wareCode"
            >
              <el-option
                v-for="(item, index) in channelOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label=" ">
            <el-button
              :loading="queryLoading"
              type="primary"
              @click="handleQuery"
            >
              {{ $t("page.search") }}
            </el-button>
            <el-button @click="handleReset">
              {{ $t("page.reset") }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button
          type="primary"
          @click="handleBatchImportInventoryDistribute"
        >批量导入</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column type="expand" width="60">
        <template #content="{ row }">
          <vxe-table
            :data="row.platDetails"
            :scroll-x="{ enabled: false }"
            :scroll-y="{ enabled: false }"
            :show-overflow="false"
            align="center"
            border
            resizable
          >
            <vxe-column field="plat" title="平台" />
            <vxe-column field="site" title="站点" />
            <vxe-column field="shop" title="店铺" />
            <vxe-column field="percentage" title="占比（%）" />
          </vxe-table>
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.No')" type="seq" />
      <vxe-column field="warehouseCode" title="履约仓库代码" />
      <vxe-column field="warehouseName" title="履约仓库名称" />
      <vxe-column field="channelName" title="备货渠道" />
      <vxe-column :title="$t('page.operate')" fixed="right">
        <template #default="{ row }">
          <el-button size="small" type="text" @click="handleDistribute(row)">
            分配
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 库存共渠道可售库存分配设置享设置 -->
    <disbribute-dialog
      :visible.sync="disbributeVisible"
      :current-row="currentRow"
      @refresh="handleQuery"
    />
    <!-- 批量导入 -->
    <batch-import
      :visible.sync="batchImportInventoryDistributeVisible"
      :options="getBatchImportInventoryDistributeOptions"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import DisbributeDialog from './components/DisbributeDialog'
import BatchImport from '@/components/BatchImport/index.vue'
import {
  getShareInventoryAssignPage,
  getShareInventorySelect,
  importPlatConfigExcel,
  importPlatConfigExcelConfig
} from '@/api/inventory-management'
import {
  BatchImportInventoryDistributeOptions,
  templateDownloadUrl
} from './const'
export default {
  name: 'ChannelInventoryDistribute',
  components: {
    DisbributeDialog,
    BatchImport
  },
  data() {
    return {
      form: {
        wareCode: '',
        chanelId: ''
      },
      maxHeight: 0,
      currentRow: null,
      disbributeVisible: false,
      queryLoading: false,
      tableData: [],
      channelOptions: [],
      warehouseOptions: [],
      rules: Object.freeze({
        wareCode: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ]
      }),
      batchImportInventoryDistributeVisible: false
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.form
      }
    },
    getBatchImportInventoryDistributeOptions() {
      return {
        importExcel: importPlatConfigExcel,
        sureImportExcel: importPlatConfigExcelConfig,
        // 模版下载链接
        templateUrl: templateDownloadUrl,
        ...BatchImportInventoryDistributeOptions
      }
    }
  },
  watch: {
    'form.wareCode'(wareCode) {
      this.chanelOptions = []
      this.getChannel(wareCode)
    }
  },
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  async mounted() {
    await this.getWarehouse()
    this.handleQuery()
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    handleReset() {
      this.$refs.formRef.resetFields()
      this.form.wareCode = this.warehouseOptions[0]?.key || ''
      this.handleQuery()
    },
    handleDistribute(row) {
      this.currentRow = row
      this.disbributeVisible = true
    },
    handleQuery() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          this.queryLoading = true
          if (this.controller) {
            this.controller.abort()
            setTimeout(() => {
              this.queryLoading = true
            })
          }
          this.controller = new AbortController()
          const signal = this.controller.signal
          if (this.queryChange) {
            this.pager.current = 1
          }
          const { datas } = await getShareInventoryAssignPage(
            this.queryParams,
            signal
          ).finally(() => {
            this.queryLoading = this.queryChange = false
          })
          this.tableData = datas || []
          this.queryLoading = false
        }
      })
    },
    handleBatchImportInventoryDistribute() {
      this.batchImportInventoryDistributeVisible = true
    },
    async getWarehouse() {
      const ret = await this.getShareInventorySelect({
        type: 0,
        key: 'warehouseOptions'
      })
      // 初始值
      this.form.wareCode = ret[0]?.key
    },
    async getChannel(wareCode) {
      this.getShareInventorySelect({
        type: 1,
        key: 'channelOptions',
        wareCode
      })
    },
    async getShareInventorySelect({ type, key, wareCode }) {
      try {
        // 下拉值选项 0履约仓库  1备货渠道   2 类目  3 品牌 4 Style
        const { datas } = await getShareInventorySelect({ type, wareCode })
        this[key] = datas || []
        return Promise.resolve(datas)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
